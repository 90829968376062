@charset "UTF-8";
/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/* Drupal Node Links */
ul.links {
  margin: 0;
  padding: 0; }
  ul.links li {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0; }
  ul.links.inline {
    display: block; }

/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */
.breadcrumb {
  margin-top: 0;
  margin-bottom: 1em; }
  @media (max-width: 991px) {
    .breadcrumb {
      display: none; } }
  .breadcrumb ol {
    display: inline;
    margin: 0;
    padding: 0; }
    .breadcrumb ol li {
      list-style: none;
      display: inline; }
  .breadcrumb .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px; }
    .breadcrumb .breadcrumb-label:after {
      content: ":"; }

.breadcrumb-seperator {
  color: #3f3f3f;
  font-size: 0.9em;
  padding: 0 5px; }

/* =============================================================================
 *   Menus
 * ========================================================================== */
/* Global Nav Objects */
/* Basics */
.menu {
  margin: 0;
  padding: 0; }
  .menu li {
    list-style: none;
    display: block;
    position: relative; }
    .menu li:last-child {
      border-bottom: 0 none; }
    .menu li a {
      display: block;
      text-decoration: none;
      padding: 7px 21.5px;
      outline: 0;
      line-height: normal; }
  .menu::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }

.region-sidebar-first a[href*="/node/add"],
.region-sidebar-second a[href*="/node/add"] {
  border: 1px solid #bcc826;
  margin-top: .25em;
  margin-bottom: .25em;
  padding: .5em !important;
  font-weight: 600;
  color: #bcc826;
  text-align: left; }
  .region-sidebar-first a[href*="/node/add"]:hover,
  .region-sidebar-second a[href*="/node/add"]:hover {
    background: #bcc826 !important;
    color: #fff !important; }

/* Horizontal */
/* Vertical (for overriding) */
/* Dropdown */
.webks-menu-hover .expanded > .menu {
  display: none; }

.webks-menu-hover .expanded:hover > a {
  background-color: white;
  color: #484848; }

.webks-menu-hover .expanded:hover > .menu {
  display: block;
  max-height: 600px;
  border-width: 0 1px 1px 1px;
  padding: 10px 0;
  border-style: solid;
  border-color: #ebebeb;
  box-shadow: 0 5px 6px -6px rgba(0, 0, 0, 0.4); }

.webks-menu-dropdown .menu .menu {
  top: 100%;
  left: 0;
  display: block;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 99;
  background: white;
  color: #484848;
  padding: 0;
  width: 100%;
  min-width: 260px;
  transition-property: max-height, border-width, box-shadow, padding; }
  .webks-menu-dropdown .menu .menu li {
    border-color: #f2f2f2; }
  .webks-menu-dropdown .menu .menu a {
    color: #484848; }
    .webks-menu-dropdown .menu .menu a:hover {
      background-color: #0e4dac;
      color: #fff; }
  .webks-menu-dropdown .menu .menu .btn-expandable-menu-item--closer {
    background-color: #ebebeb; }
  .webks-menu-dropdown .menu .menu .menu {
    background-color: #ebebeb; }
    .webks-menu-dropdown .menu .menu .menu .btn-expandable-menu-item--closer {
      background-color: #e0e0e0; }
    .webks-menu-dropdown .menu .menu .menu .menu {
      background-color: #e0e0e0; }
      .webks-menu-dropdown .menu .menu .menu .menu .btn-expandable-menu-item--closer {
        background-color: #d9d9d9; }
      .webks-menu-dropdown .menu .menu .menu .menu .menu {
        background-color: #d9d9d9; }

.webks-menu-dropdown .menu .expanded.open {
  z-index: 9999; }
  .webks-menu-dropdown .menu .expanded.open > .menu {
    display: block;
    max-height: 600px; }

.webks-menu-dropdown .menu .expanded:not(.expandable-menu-item).open > a {
  background-color: white;
  color: #484848; }

.webks-menu-dropdown .menu .menu .menu, .webks-menu-dropdown--horizontal .menu .menu {
  left: 100%;
  top: 0;
  bottom: auto;
  right: auto; }

/* Expandable Menu Items (touch support) */
.expandable-menu-item > .btn-expandable-menu-item {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  display: block;
  text-align: center;
  position: absolute;
  right: 0;
  top: 8px;
  cursor: pointer;
  text-indent: -9000px;
  background: linear-gradient(to bottom, #0e4dac 0%, #074093 100%); }
  .expandable-menu-item > .btn-expandable-menu-item--opener::before {
    content: ""; }
  .expandable-menu-item > .btn-expandable-menu-item--closer::before {
    content: ""; }
  .expandable-menu-item > .btn-expandable-menu-item::before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    font-family: "iconfont";
    text-indent: 0; }

.expandable-menu-item > a {
  margin-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.expandable-menu-item > .menu {
  display: none; }

.expandable-menu-item.open > .menu {
  display: block; }

.expandable-menu-item .expandable-menu-item > .btn-expandable-menu-item {
  transform: scale(0.8);
  background: #3f3f3f; }

.expandable-menu-item .expandable-menu-item .webks-flyout {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

/* Block / Pane / Custom Classes */
/* Dropdown */
/* Menu Specific */
/* -- Main Menu */
@media only screen and (min-width: 992px) {
  .navigation-bar-wrapper .menu-block-wrapper > .menu {
    font-weight: 300; }
    .navigation-bar-wrapper .menu-block-wrapper > .menu > li {
      float: left;
      border-bottom: 0 none;
      border-right: 1px solid #ebebeb; }
      .navigation-bar-wrapper .menu-block-wrapper > .menu > li.last {
        border-right: 0; } }

.navigation-bar-wrapper .menu-block-wrapper > .menu > li {
  margin-right: 3px; }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li > a {
    height: 50px;
    padding: 0 13px;
    line-height: 50px;
    white-space: nowrap;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    box-shadow: 0 2px 0 #074093 inset;
    border-radius: 5px 5px 0 0;
    background: linear-gradient(to bottom, #0e4dac 0%, #074093 100%); }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li:first-child > a {
    padding-left: 26px; }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li:last-child {
    margin-right: 0; }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li:hover > a, .navigation-bar-wrapper .menu-block-wrapper > .menu > li.active > a, .navigation-bar-wrapper .menu-block-wrapper > .menu > li.active-trail > a {
    border-color: #ebebeb;
    background: white;
    box-shadow: none;
    color: #484848; }
    .navigation-bar-wrapper .menu-block-wrapper > .menu > li:hover > a::before, .navigation-bar-wrapper .menu-block-wrapper > .menu > li.active > a::before, .navigation-bar-wrapper .menu-block-wrapper > .menu > li.active-trail > a::before {
      color: #0e4dac; }

/* -- Mobile Nav Bar */
@media only screen and (max-width: 991px) {
  body {
    padding-top: 57px !important; } }

.drowl-headroom-wrapper {
  background: white; }
  @media only screen and (min-width: 992px) {
    .drowl-headroom-wrapper {
      display: none; } }
  .drowl-headroom-wrapper > .region-inner {
    display: flex;
    overflow: hidden; }
    @media only screen and (max-width: 1419px) {
      .drowl-headroom-wrapper > .region-inner {
        padding-left: 15px;
        padding-right: 15px;
        justify-content: space-between; } }
  .drowl-headroom-wrapper .block {
    margin: 0 0 0 2px; }
  .drowl-headroom-wrapper .block-block-6 {
    float: left;
    order: 1;
    align-self: center;
    max-width: 30%;
    margin-left: 0;
    line-height: 0; }
    .drowl-headroom-wrapper .block-block-6 img {
      max-height: 50px; }
  .drowl-headroom-wrapper .block-search {
    float: right;
    order: 2;
    max-width: 50%;
    margin-left: auto; }
    @media (max-width: 991px) {
      .drowl-headroom-wrapper .block-search .form-type-searchfield {
        width: 100%; } }
    @media (max-width: 991px) {
      .drowl-headroom-wrapper .block-search .form-actions {
        display: none; } }
  .drowl-headroom-wrapper .block-block-5,
  .drowl-headroom-wrapper .block-multiblock-5 {
    order: 3;
    float: right; }
  .drowl-headroom-wrapper.shrinked-navigation-bar .block-search {
    float: left;
    width: 75%;
    padding-right: 15px;
    box-sizing: border-box; }
  .drowl-headroom-wrapper.shrinked-navigation-bar .block-menu-block {
    float: right;
    width: 25%; }
  @media only screen and (min-width: 992px) {
    .drowl-headroom-wrapper .menu-block-wrapper > .menu {
      font-weight: 300; }
      .drowl-headroom-wrapper .menu-block-wrapper > .menu > li {
        float: left;
        border-bottom: 0 none;
        border-right: 1px solid #ebebeb; }
        .drowl-headroom-wrapper .menu-block-wrapper > .menu > li.last {
          border-right: 0; } }
  .drowl-headroom-wrapper--unpinned .drowl-headroom-wrapper--invisible {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .drowl-headroom-wrapper--unpinned .drowl-headroom-wrapper--invisible {
        top: -100%; } }

/* =============================================================================
 *   Headroom.js | Mobile Helpers Bar
 * ========================================================================== */
.headroom {
  z-index: 999;
  transition: transform 200ms linear;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 1139px) {
    .headroom {
      transform: translateY(0) !important;
      margin-top: -1px !important;
      width: 100% !important;
      padding: 0 !important;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      display: block !important;
      z-index: 9999 !important; } }
  .headroom > .region-inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 5px 30px; }
    @media only screen and (max-width: 1139px) {
      .headroom > .region-inner {
        align-items: center; } }
    @media only screen and (max-width: 767px) {
      .headroom > .region-inner {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .headroom--not-top {
    width: 100% !important;
    padding: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    z-index: 9999; }
  .headroom--pinned {
    transform: translateY(0); }
  @media (min-width: 768px) {
    .headroom--unpinned {
      transform: translateY(-100%);
      margin-top: -1px; } }

.touchmenu-open .drowl-headroom-wrapper {
  top: -100%; }

/* Shrinked Main Menu (Off Canvas)*/
.webks-offcanvas-trigger,
.shrinked-main-menu-switcher {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent !important;
  border-color: #d0d0d0 !important;
  color: #484848 !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  text-indent: -9000px;
  position: relative;
  padding: 0;
  min-width: 42px;
  line-height: 42px;
  display: block; }
  .webks-offcanvas-trigger:hover,
  .shrinked-main-menu-switcher:hover {
    background-color: transparent !important;
    border-color: #e4e4e4 !important;
    color: #0e4dac !important; }
  .webks-offcanvas-trigger:active, .webks-offcanvas-trigger:focus,
  .shrinked-main-menu-switcher:active,
  .shrinked-main-menu-switcher:focus {
    background-color: transparent !important;
    border-color: #c3c3c3 !important;
    color: #484848 !important; }
  .webks-offcanvas-trigger::before,
  .shrinked-main-menu-switcher::before {
    text-indent: 0;
    float: left;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    display: inline-block;
    font-family: "iconfont";
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    text-align: center;
    content: ""; }
    .webks-offcanvas-trigger::before else,
    .shrinked-main-menu-switcher::before else {
      line-height: 1; }
  .webks-offcanvas-trigger:hover,
  .shrinked-main-menu-switcher:hover {
    text-decoration: none; }

.shrinked-main-menu-triggers {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none; }
  .shrinked-main-menu-triggers li {
    display: block;
    border: 0 none; }

.shrinked-navigation-bar .menu-block-wrapper > .menu > li > .menu {
  top: 0; }

.shrinked-navigation-bar .shrinked-main-menu-triggers {
  display: block; }

.shrinked-main-menu-origin {
  display: none; }

/* Off Canvas Menu */
.webks-offcanvas-layer {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  height: 100%;
  max-height: none !important;
  width: 90%;
  max-width: 350px;
  background-color: #0e4dac; }
  .webks-offcanvas-layer.open {
    display: block; }
  .webks-offcanvas-layer .btn-expandable-menu-item--closer {
    background-color: #0c4090; }
  .webks-offcanvas-layer .menu {
    position: static !important; }
    .webks-offcanvas-layer .menu .menu {
      background-color: #0c4090; }
      .webks-offcanvas-layer .menu .menu .btn-expandable-menu-item--closer {
        background-color: #0b3c86; }
      .webks-offcanvas-layer .menu .menu .menu {
        background-color: #0b3c86; }
        .webks-offcanvas-layer .menu .menu .menu .btn-expandable-menu-item--closer {
          background-color: #093473; }
        .webks-offcanvas-layer .menu .menu .menu .menu {
          background-color: #093473; }
  .webks-offcanvas-layer li {
    border-bottom: 1px solid #0c4294 !important;
    font-size: 16px;
    line-height: 20px; }
    .webks-offcanvas-layer li a {
      background: none !important;
      color: #fff !important;
      padding: 10px 20px !important; }
    .webks-offcanvas-layer li.expanded.open > a {
      border-bottom: 1px solid #0c4090 !important; }
  .webks-offcanvas-layer .expandable-menu-item > a {
    margin-right: 40px; }
  .webks-offcanvas-layer .expandable-menu-item > .btn-expandable-menu-item {
    width: 40px;
    max-height: 40px; }
    .webks-offcanvas-layer .expandable-menu-item > .btn-expandable-menu-item::before {
      width: 40px;
      height: 40px;
      line-height: 40px; }
  .webks-offcanvas-layer .menu-mlid-close {
    display: none; }
  .webks-offcanvas-layer .block-search-form {
    border-bottom: 1px solid #0c4294;
    margin-bottom: 5px;
    padding: 10px 60px 10px 20px !important;
    float: none !important;
    width: 100% !important; }
    .webks-offcanvas-layer .block-search-form .form-submit {
      background-color: #0c4294 !important; }
  .webks-offcanvas-layer .block-menu-block {
    padding-top: 55px; }
  .webks-offcanvas-layer .block-search-form + .block-menu-block {
    padding-top: 0; }

button.drowl-offcanvas__close-btn {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent !important;
  border-color: #074093 !important;
  color: #fff !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  text-indent: -9000px;
  position: relative;
  padding: 0;
  min-width: 42px;
  line-height: 42px;
  position: absolute;
  right: 7px;
  top: 10px;
  z-index: 3;
  background-color: #0c4294 !important; }
  button.drowl-offcanvas__close-btn:hover {
    background-color: transparent !important;
    border-color: #084bab !important;
    color: #fff !important; }
  button.drowl-offcanvas__close-btn:active, button.drowl-offcanvas__close-btn:focus {
    background-color: transparent !important;
    border-color: #06357b !important;
    color: #fff !important; }
  button.drowl-offcanvas__close-btn::before {
    text-indent: 0;
    float: left;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    display: inline-block;
    font-family: "iconfont";
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    text-align: center;
    content: ""; }
    button.drowl-offcanvas__close-btn::before else {
      line-height: 1; }
  button.drowl-offcanvas__close-btn:hover {
    text-decoration: none; }

@media only screen and (min-width: 768px) {
  .shrinked-main-menu-triggers li a {
    text-indent: 0; }
    .shrinked-main-menu-triggers li a::before {
      display: inline-block;
      position: static;
      margin-right: 5px;
      margin-bottom: -5px;
      width: auto; } }

/* =============================================================================
 *   Pager
 * ========================================================================== */
.pager {
  clear: both;
  margin: 0;
  text-align: center;
  list-style-type: none; }
  .pager li {
    margin: 0 5px 0 0;
    display: inline-block;
    vertical-align: middle; }
  .pager .pager-current {
    padding: 12px 6px;
    background: transparent;
    font-weight: 600;
    color: #0e4dac; }
  .pager a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #0e4dac !important;
    background-color: #0e4dac !important;
    color: #fff !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px; }
    .pager a:hover {
      border-color: #1058c4 !important;
      background-color: #1058c4 !important;
      color: #fff !important; }
    .pager a:active, .pager a:focus {
      border-color: #0c4294 !important;
      background-color: #0c4294 !important;
      color: #fff !important; }
    .pager a:hover {
      text-decoration: none; }

/* =============================================================================
 *   Tabs
 * ========================================================================== */
/* Primary */
@media only screen and (max-width: 1139px) {
  .tabs-wrapper {
    position: relative; }
    .tabs-wrapper::before {
      content: " ";
      width: 15px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
      background: rgba(255, 255, 255, 0);
      /* Old browsers */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, #fff));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 100%);
      /* IE10+ */
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
      /* W3C */ } }

ul.primary,
#login-links,
.ui-tabs .ui-tabs-nav,
.horizontal-tabs-list {
  border-color: #ebebeb;
  border-width: 0;
  border-style: solid;
  border-radius: 0;
  position: relative;
  padding: 0;
  list-style: none;
  background: none;
  margin: 0.5em 0 12.5px 0;
  line-height: normal; }
  @media only screen and (max-width: 1139px) {
    ul.primary,
    #login-links,
    .ui-tabs .ui-tabs-nav,
    .horizontal-tabs-list {
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden; } }
  ul.primary li,
  #login-links li,
  .ui-tabs .ui-tabs-nav li,
  .horizontal-tabs-list li {
    border: 0 none;
    background: none;
    color: #484848;
    float: none;
    display: inline-block;
    margin-right: 3px; }
    ul.primary li a.active,
    ul.primary li a.lt-active,
    ul.primary li.selected a,
    ul.primary li.ui-tabs-active > a,
    #login-links li a.active,
    #login-links li a.lt-active,
    #login-links li.selected a,
    #login-links li.ui-tabs-active > a,
    .ui-tabs .ui-tabs-nav li a.active,
    .ui-tabs .ui-tabs-nav li a.lt-active,
    .ui-tabs .ui-tabs-nav li.selected a,
    .ui-tabs .ui-tabs-nav li.ui-tabs-active > a,
    .horizontal-tabs-list li a.active,
    .horizontal-tabs-list li a.lt-active,
    .horizontal-tabs-list li.selected a,
    .horizontal-tabs-list li.ui-tabs-active > a {
      position: relative;
      padding-top: 8px;
      color: #0e4dac;
      border-color: #0e4dac; }
    ul.primary li a,
    #login-links li a,
    .ui-tabs .ui-tabs-nav li a,
    .horizontal-tabs-list li a {
      display: block;
      padding: 7px 10px;
      text-decoration: none;
      outline: 0;
      border-width: 0 0 2px 0;
      border-style: solid;
      border-color: #ebebeb;
      border-radius: 0;
      background: transparent; }
      ul.primary li a strong,
      #login-links li a strong,
      .ui-tabs .ui-tabs-nav li a strong,
      .horizontal-tabs-list li a strong {
        font-weight: normal; }
      ul.primary li a:active, ul.primary li a:hover, ul.primary li a:focus,
      #login-links li a:active,
      #login-links li a:hover,
      #login-links li a:focus,
      .ui-tabs .ui-tabs-nav li a:active,
      .ui-tabs .ui-tabs-nav li a:hover,
      .ui-tabs .ui-tabs-nav li a:focus,
      .horizontal-tabs-list li a:active,
      .horizontal-tabs-list li a:hover,
      .horizontal-tabs-list li a:focus {
        color: #484848;
        border-color: #0e4dac; }
      ul.primary li a:focus,
      #login-links li a:focus,
      .ui-tabs .ui-tabs-nav li a:focus,
      .horizontal-tabs-list li a:focus {
        outline: 0;
        border-color: #d7d7d7; }

/* -- Tabs Content Wrapper */
.horizontal-tabs-list + .horizontal-tabs-panes {
  margin-top: -14.5px; }

.horizontal-tabs-list::after {
  height: calc(100% - 2px); }

.horizontal-tabs-panes {
  clear: both;
  padding: 25px 43px;
  background: white;
  margin-bottom: 25px;
  border: 1px solid #ebebeb;
  border-top-width: 2px; }
  .horizontal-tabs-panes .field-group-htab {
    padding: 0; }
  .horizontal-tabs-panes .horizontal-tabs-pane {
    border: 0 none;
    padding: 0; }
    .horizontal-tabs-panes .horizontal-tabs-pane > legend {
      display: none !important; }
    .horizontal-tabs-panes .horizontal-tabs-pane > fieldset,
    .horizontal-tabs-panes .horizontal-tabs-pane > .fieldset-wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important; }
  .horizontal-tabs-panes .horizontal-tab-hidden {
    display: none; }

/* Secondary */
ul.secondary {
  margin: 0 0 8px 0;
  padding: 0.55em 10px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  line-height: normal;
  border-color: #ebebeb;
  background: white; }
  ul.secondary::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  ul.secondary li {
    border-color: #eee;
    float: left;
    margin-right: 7px;
    padding-right: 7px;
    list-style: none;
    border-width: 0 1px 0 0;
    border-style: solid; }
    ul.secondary li:last-child {
      border-width: 0; }
    ul.secondary li a {
      display: block;
      text-decoration: none; }

/* Vertical tabs */
div.vertical-tabs {
  margin: 1em 0px 1em 0;
  width: 100%;
  border: 0 none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box; }
  div.vertical-tabs ul.vertical-tabs-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-width: 0 3px 0 0;
    border-style: solid;
    border-color: #0e4dac;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box; }
    @media only screen and (min-width: 768px) {
      div.vertical-tabs ul.vertical-tabs-list {
        width: 30%;
        float: left; } }
    div.vertical-tabs ul.vertical-tabs-list li {
      border: 0 none;
      margin-bottom: 2px; }
      div.vertical-tabs ul.vertical-tabs-list li a {
        color: #484848;
        text-decoration: none;
        display: block;
        padding: 10px;
        line-height: 1.1em;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        border-color: white;
        background-color: white; }
        div.vertical-tabs ul.vertical-tabs-list li a .form-required {
          color: white; }
        div.vertical-tabs ul.vertical-tabs-list li a strong {
          font-weight: normal; }
        div.vertical-tabs ul.vertical-tabs-list li a .summary {
          display: block;
          color: #959ba4;
          font-size: 12px; }
        div.vertical-tabs ul.vertical-tabs-list li a:hover {
          outline: 0; }
      div.vertical-tabs ul.vertical-tabs-list li:hover {
        border-color: #ebebeb;
        background: white; }
      div.vertical-tabs ul.vertical-tabs-list li.selected a {
        background: #0e4dac;
        border-color: #0e4dac;
        border-radius: 8px 0 0 8px; }
        div.vertical-tabs ul.vertical-tabs-list li.selected a .summary {
          color: #fff;
          opacity: 0.8; }
      div.vertical-tabs ul.vertical-tabs-list li.selected strong {
        color: white; }
  div.vertical-tabs .vertical-tabs-panes {
    clear: both;
    border-width: 0 0 3px 0;
    border-style: solid;
    border-color: #0e4dac;
    margin-left: -3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box; }
    @media only screen and (min-width: 768px) {
      div.vertical-tabs .vertical-tabs-panes {
        clear: none;
        float: left;
        width: 70%;
        border-width: 0 0 0 3px; } }
  div.vertical-tabs .vertical-tabs-pane {
    margin: 0;
    border: 0 none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box; }
    div.vertical-tabs .vertical-tabs-pane > legend {
      display: none; }

/* Action Links */
ul.action-links {
  margin: 10px 0 25px 0;
  padding: 0;
  list-style: none; }
  ul.action-links > li > a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    background-color: transparent !important;
    border-color: #d0d0d0 !important;
    color: #484848 !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px; }
    ul.action-links > li > a:hover {
      background-color: transparent !important;
      border-color: #e4e4e4 !important;
      color: #0e4dac !important; }
    ul.action-links > li > a:active, ul.action-links > li > a:focus {
      background-color: transparent !important;
      border-color: #c3c3c3 !important;
      color: #484848 !important; }
    ul.action-links > li > a:hover {
      text-decoration: none; }

/* jQuery Tabs */
.ui-tabs {
  border: 0 none; }

#backtotop {
  background-color: transparent !important;
  border: 0 none !important; }
