// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/* Drupal Node Links */
ul.links {
  margin: 0;
  padding: 0;
  li {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0;
  }
  &.inline {
    display: block; // why?
  }
}

/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */
.breadcrumb {
  margin-top: 0;
  margin-bottom: 1em;
  @include media-breakpoint-down(md){
    display:none;
  }
  ol {
    display: inline;
    margin: 0;
    padding: 0;
    li{
      list-style: none;
      display: inline;
    }
  }
  .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px;
    &:after {
      content: ":";
    }
  }
}
.breadcrumb-seperator{
  color:$grey_dark;
  font-size:0.9em;
  padding:0 5px;
}

/* =============================================================================
 *   Menus
 * ========================================================================== */

/* Global Nav Objects */
@mixin menu-flyout{
  position:absolute;
  z-index:99;

  background:$menu_flyout_background;
  color:$menu_flyout_color;
  padding:0;
  width:100%;
  min-width:260px;

  // Link & Border Color
  li{
    border-color:darken($menu_flyout_background, 5%);
  }
  a{
    color:$menu_flyout_color;
    &:hover{
      background-color:$highlight;
      color:$highlight_text_color;
    }
  }
}

/* Basics */
.menu{
  margin:0;
  padding:0;
  li{
    list-style:none;
    display:block;
    position:relative;
    &:last-child{
      border-bottom:0 none;
    }
    a{
      display:block;
      text-decoration:none;
      padding:7px $grid / 2;
      outline:0;
      line-height:normal;
    }
  }
  @include clearfix-after;
}

// Sidebar Menu
.region-sidebar-first,
.region-sidebar-second{
  a[href*="/node/add"]{
    border: 1px solid $green;
    margin-top:.25em;
    margin-bottom:.25em;
    padding:.5em !important;
    font-weight: $font_weight_bold;
    color: $green;
    text-align:left;
    &:hover{
      background: $green !important;
      color: $white !important;
    }
  }
}

/* Horizontal */
@mixin menu--horizontal{
  // Attiontion: Defining the breakpoint has to be located in the block styling (caus we cant determine here, how long the menu is and in wich content it exists)
  font-weight:$font_weight_thin;
  > li{
    float:left;
    border-bottom:0 none;
    border-right:1px solid $grey_light;
    &.last{ border-right:0; }
  }
}

/* Vertical (for overriding) */
@mixin menu--vertical{
  > li{
    float:none;
    border-right:0 none;
    border-bottom:1px solid $grey_light;
  }
}

/* Dropdown */
%menu--hover{
  // We need this to have the ability, to remove the hover class, to get a proper touch menu
  .expanded{
    > .menu{
      display:none;
    }
    &:hover{
      > a{
        background-color:$menu_flyout_background;
        color:$menu_flyout_color;
      }
      > .menu{
        display:block;
        max-height:600px;
        border-width:0 1px 1px 1px;
        padding:10px 0;
        border-style:solid;
        border-color:$grey_light;
        box-shadow:0 5px 6px -6px $shadow_intensity;
      }
    }
  }
}

$dropdownDepth_1_color: darken($menu_flyout_background, 8%);
$dropdownDepth_2_color: darken($menu_flyout_background, 12%);
$dropdownDepth_3_color: darken($menu_flyout_background, 15%);
%menu--dropdown{
  .menu{
    top:100%;
    left:0;
    display:block;
    max-height:0;
    overflow:hidden;
    @include menu-flyout;
    transition-property: max-height, border-width, box-shadow, padding;
    .btn-expandable-menu-item--closer{
      background-color:$dropdownDepth_1_color;
    }
    .menu{
      // Depth 1
      background-color:$dropdownDepth_1_color;
      .btn-expandable-menu-item--closer{
        background-color:$dropdownDepth_2_color;
      }
      .menu{
        // Depth 2
        background-color:$dropdownDepth_2_color;
        .btn-expandable-menu-item--closer{
           background-color:$dropdownDepth_3_color;
        }
        .menu{
          // Depth 3
          background-color:$dropdownDepth_3_color;
        }
      }
    }
  }
  .expanded{
    &.open{
      z-index:9999;
      > .menu{
        display:block;
        max-height:600px;
      }
    }
  }
  .expanded:not(.expandable-menu-item){
    &.open{
      > a{
        background-color:$menu_flyout_background;
        color:$menu_flyout_color;
      }
    }
  }
}
%menu--dropdown-horizontal{
  // Secondary Menu Flyouts opens right, instead of bottom
  .menu{
    left:100%;
    top:0;
    bottom:auto;
    right:auto;
  }
}

/* Expandable Menu Items (touch support) */
.expandable-menu-item{
  > .btn-expandable-menu-item{
    width:25px;
    height:25px;
    line-height:25px;
    border-radius:50%;
    text-align:center;
    color:$highlight_text_color;
    display:block;
    text-align:center;
    position:absolute;
    right:0;
    top:8px;
    cursor:pointer;
    text-indent:-9000px;
    background: linear-gradient(to bottom, $highlight 0%, $highlight_dark 100%);
    &--opener{
      &::before{
        @include get-ico-only('arrow-thin-bottom');
      }
    }
    &--closer{
      &::before{
        @include get-ico-only('arrow-thin-top');
      }
    }
    &::before{
      position:absolute;
      left:0;
      top:0;
      font-size:12px;
      height:100%;
      width:100%;
      vertical-align:middle;
      font-family:$iconfont;
      text-indent:0;
    }
  }
  > a{
    margin-right:40px; // == width .btn-opener
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  > .menu{
    display:none;
  }
  &.open{
    > .menu{
      display:block;
    }
  }
  // Nested
  .expandable-menu-item{
    > .btn-expandable-menu-item{
      transform:scale(.8);
      background: $grey_dark;
   }
   .webks-flyout{
     padding-top:0 !important;
     padding-bottom:0 !important;
   }
  }
}


/* Block / Pane / Custom Classes */
// Classes to set to a menu wrapper

/* Dropdown */
.webks-menu-hover{
  @extend %menu--hover;
}
.webks-menu-dropdown{
  .menu{
    @extend %menu--dropdown;
    .menu{
      @extend %menu--dropdown-horizontal;
    }
  }
}
.webks-menu-dropdown--horizontal{
  .menu{
    @extend %menu--dropdown-horizontal;
  }
}

/* Menu Specific */
/* -- Main Menu */
.region-navigation-bar{

}

.navigation-bar-wrapper{
  .menu-block-wrapper > .menu{
    @media #{$large-up}{ // Breakpoint when the menu gets horizontal in this region
      @include menu--horizontal;
    }
    // Main Menu Styles goes here
    > li{
      margin-right:3px;
      > a{
        height:50px;
        padding:0 13px;
        line-height:50px;
        white-space:nowrap;
        border-width:1px 1px 0 1px;
        border-style:solid;
        overflow:hidden;
        text-overflow:ellipsis;
        color:$highlight_text_color;
        box-shadow:0 2px 0 $highlight_dark inset;
        border-radius:$border_radius_small $border_radius_small 0 0;
        background: linear-gradient(to bottom, $highlight 0%, $highlight_dark 100%);
      }
      &:first-child{
        > a{
          padding-left:$page_outer_grid - 4px; // 4px = white glass frame
        }
      }
      &:last-child{
        margin-right:0;
      }
      &:hover,
      &.active,
      &.active-trail{
        > a{
          border-color:$grey_light;
          background:white;
          box-shadow:none;
          color:$text_color;
          &::before{
            color:$highlight;
          }
        }
      }
    }
  }
}
/* -- Mobile Nav Bar */
body{
  @media #{$medium-down}{
    // Reserved Space for the Mobile Nav Bar
    padding-top:$mobile_menu_bar_height + $thin_grid !important;
  }
}

.drowl-headroom-wrapper{
  background:white;
  @media #{$large-up}{
    // Hide on larger devices show when the default menu is out of the viewport (via headroom.js)
    display:none;
  }
  > .region-inner{
    display: flex;
    overflow:hidden; // clearfix
    @media #{$xlarge-down}{
      padding-left:$thin_grid;
      padding-right:$thin_grid;
      justify-content: space-between;
    }
  }
  .block{
    margin:0 0 0 2px;
  }
  // Logo
  .block-block-6{ // page logo inside a block
    float:left;
    order:1;
    align-self: center;
    max-width:30%;
    margin-left:0;
    line-height:0;
    img{
      max-height:50px;
    }
  }
  // Search
  .block-search{
    float:right;
    order:2;
    max-width:50%;
    margin-left:auto;
    .form-type-searchfield{
      @include media-breakpoint-down(md){
        width:100%;
      }
    }
    .form-actions{
      @include media-breakpoint-down(md){
        display:none;
      }
    }
  }
  // Menu Trigger
  .block-block-5,
  .block-multiblock-5{ // menu trigger blocks
    order:3;
    float:right;
  }
  &.shrinked-navigation-bar{
    .block-search{
      float:left;
      width:75%;
      padding-right:$thin_grid;
      box-sizing: border-box;
    }
    .block-menu-block{
      float:right;
      width:25%;
    }
  }
  .menu-block-wrapper > .menu{
    @media #{$large-up}{ // Breakpoint when the menu gets horizontal in this region
      @include menu--horizontal;
    }
    // Main Menu Styles goes here
  }

  // Hide headroom bar if the menu flyout is open (or the regular menu is visible)
  &--unpinned
  &--invisible{
    // override module default behavoir
    display:block;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    @media #{$medium-up}{
      top:-100%;
    }
  }
}

/* =============================================================================
 *   Headroom.js | Mobile Helpers Bar
 * ========================================================================== */
.headroom {
  z-index:999;
  transition: transform 200ms linear;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  // Why transform none?
  // transform: none !important;
  // -moz-transform: none !important;
  // -webkit-transform: none !important;
  // Show all the time on small devices
  @media #{$large_down}{
    transform: translateY(0) !important;
    margin-top: -1px !important;
    width: 100% !important;
    padding: 0 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    display: block !important;
    z-index: 9999 !important;
  }
  > .region-inner{
    max-width:$page_max_width;
    margin:0 auto;
    padding:5px $page_outer_grid;
    @media #{$large_down}{
      align-items:center;
    }
    @media #{$small_only}{
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  &--not-top{
    width:100% !important; // Prevent from getting overwritten
    padding:0 !important; // Prevent from getting overwritten
    position:fixed;
    top:0;
    left:0;
    display:block;
    z-index:9999;
  }
  &--pinned {
    transform: translateY(0);
  }
  &--unpinned {
    @include media-breakpoint-up(md){
      transform: translateY(-100%);
      margin-top:-1px; // compensate border
    }
  }
}

.touchmenu-open .drowl-headroom-wrapper{
  // @include transform(translateY(-100%)); // this wont work because translate touches fixed elements also
  top:-100%;
}

/* Shrinked Main Menu (Off Canvas)*/
$offCanvasItemPaddingY: 10px;
$offCanvasItemPaddingX: $mobile_grid;
$offCanvasItemFontSize:$font_size_px;
$offCanvasItemLineHeight:$font_size_px + 4px;
$offCanvasExpItemTriggerWidth:($offCanvasItemPaddingY * 2) + $offCanvasItemLineHeight;

.webks-offcanvas-trigger,
.shrinked-main-menu-switcher{
  @if($show_touch_menu_label == false){
    @include btn('ghost', 'default', 'menu', 'true');
  }@else{
    @include btn('ghost', 'default', 'menu');
  }
  display:block;
}

.shrinked-main-menu-triggers{
  list-style:none;
  margin:0;
  padding:0;
  display:none;
  li{
    display:block;
    border:0 none;
  }
}

.shrinked-navigation-bar{
  .menu-block-wrapper > .menu > li > .menu{
    top:0;
  }
  .shrinked-main-menu-triggers{
    display:block;
  }
}
.shrinked-main-menu-origin{
  // hide default menu
  display:none;
}

/* Off Canvas Menu */
.webks-offcanvas-layer{
  display:none;
  position:fixed;
  right:0;
  top:0;
  z-index:10000;
  height:100%;
  max-height:none !important; // Override Max-height Script (needed for normal, none offcanvas dropdowns)
  width:90%;
  max-width:350px;
  background-color:$menu_offcanvas_background;
  &.open{
    display:block;
  }
  .btn-expandable-menu-item--closer{
    background-color:$menu_offcanvas_background__depth_1;
  }
  .menu{
    position:static !important;
    .menu{
      // Depth 1
      background-color:$menu_offcanvas_background__depth_1;
      .btn-expandable-menu-item--closer{
        background-color:$menu_offcanvas_background__depth_2;
      }
      .menu{
        // Depth 2
        background-color:$menu_offcanvas_background__depth_2;
        .btn-expandable-menu-item--closer{
           background-color:$menu_offcanvas_background__depth_3;
        }
        .menu{
          // Depth 3
          background-color:$menu_offcanvas_background__depth_3;
        }
      }
    }
  }
  li{
    border-bottom:1px solid darken($menu_offcanvas_background, 5%) !important;
    font-size:$offCanvasItemFontSize;
    line-height:$offCanvasItemLineHeight;
    a{
      background: none !important;
      color:$menu_offcanvas_color !important;
      padding:$offCanvasItemPaddingY $offCanvasItemPaddingX !important;
    }
    &.expanded{
      &.open{
        > a{
          border-bottom:1px solid darken($menu_offcanvas_background, 6%) !important;
        }
      }
    }
  }
  .expandable-menu-item{
    > a{
      margin-right:$offCanvasExpItemTriggerWidth;
    }
    > .btn-expandable-menu-item{
      width:$offCanvasExpItemTriggerWidth;
      max-height:$offCanvasExpItemTriggerWidth;
      &::before{
        width:$offCanvasExpItemTriggerWidth;
        height:$offCanvasExpItemTriggerWidth;
        line-height:$offCanvasExpItemTriggerWidth;
      }
    }
  }
  .menu-mlid-close{
    display:none;
  }
  // Search Block
  .block-search-form{
    border-bottom:1px solid darken($menu_offcanvas_background, 5%);
    margin-bottom:5px;
    padding:10px 60px 10px $mobile_grid !important; // 60px free space for the close button
    float:none !important;
    width:100% !important;
    .form-submit{
      background-color:darken($menu_offcanvas_background, 5%) !important;
    }
  }
  .block-menu-block{
    // free space for the close button
    padding-top:55px;
  }
  .block-search-form + .block-menu-block{
    // Remove free space if the search block exists
    padding-top:0;
  }
}
button.drowl-offcanvas__close-btn{
  @include btn('ghost-dark', 'default', 'circle-remove', 'true');
  position:absolute;
  right:7px;
  top:10px;
  z-index:3;
  background-color:darken($menu_offcanvas_background, 5%) !important;
}

@media #{$medium-up}{
  .shrinked-main-menu-triggers li a{
    text-indent:0;
    &::before{
      display:inline-block;
      position:static;
      margin-right:5px;
      margin-bottom:-5px;
      width:auto;
    }
  }
}

/* =============================================================================
 *   Pager
 * ========================================================================== */
.pager{
  clear: both;
  margin: 0;
  text-align: center;
  list-style-type: none;
  li{
    margin:0 5px 0 0;
    display: inline-block;
    vertical-align:middle;
  }
  .pager-current{
    padding:12px 6px; // was 3px 5px
    background:transparent;
    font-weight: $font_weight_bold;
    color: $highlight;
  }
  a{
    @include btn('default', 'sm'); // size was 'xs'
  }
  // .pager-current{
  //   @include btn-mod('active');
  // }
}

/* =============================================================================
 *   Tabs
 * ========================================================================== */
/* Primary */

$tabs_wrapper_space_bottom: $grid_vertical / 2;
$tabs_wrapper_border_top: 2px;

.tabs-wrapper{
  @media #{$large-down}{
    position:relative;
    &::before{
      // fade out effect for the overflow scrolling behavoir
      content:" ";
      width:15px;
      height:100%;
      position:absolute;
      right:0;
      top:0;
      z-index:5;
      @include horizontal-gradient(rgba(255,255,255,0), #fff);
    }
  }
}

ul.primary,
#login-links,
.ui-tabs .ui-tabs-nav,
.horizontal-tabs-list{
  border-color:$grey_light;
  border-width:0;
  border-style:solid;
  border-radius:0;
  position:relative;
  padding:0;
  list-style:none;
  background:none;
  margin:0.5em 0 $tabs_wrapper_space_bottom 0;
  line-height:normal;
  @media #{$large-down}{
    // make the container horizontally scrollable if there to many tabs (only on medium and small devices - cause we do not want big fat ugly a desktop browser scrollbar there)
    white-space:nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  li{
    // Undo some jquery ui styles
    border:0 none;
    background:none;
    color:$text_color;
    float:none;
    display:inline-block;
    margin-right:3px;
    a.active,
    a.lt-active,
    &.selected a,
    &.ui-tabs-active > a{
      position:relative;
      padding-top:8px; // compensate negative amount
      color:$highlight;
      border-color:$highlight;
    }
    a{
      display:block;
      padding:7px 10px;
      text-decoration:none;
      outline:0;
      border-width:0 0 2px 0;
      border-style:solid;
      border-color:$grey_light;
      border-radius:0;
      background:transparent;
      strong{
        font-weight:normal; // "Bugfix" for the fieldgroup tabs, somebody thought its a good idea to capsulate it with STRONG
      }
      &:active,
      &:hover,
      &:focus{
        color:$text_color;
        border-color:$highlight;
      }
      &:focus{
        outline:0;
        border-color:darken($grey_light, 8%);
      }
    }
  }
}

/* -- Tabs Content Wrapper */
.horizontal-tabs-list{
  + .horizontal-tabs-panes{
    margin-top:($tabs_wrapper_space_bottom + $tabs_wrapper_border_top) * -1;
  }
  &::after{
    height:calc(100% - #{$tabs_wrapper_border_top});
  }
}
.horizontal-tabs-panes{
  clear:both;
  padding:$grid_vertical $grid;
  background:white;
  margin-bottom:$grid_vertical;
  border:1px solid $grey_light;
  border-top-width:$tabs_wrapper_border_top;
  .field-group-htab{
    padding:0;
  }
  .horizontal-tabs-pane{
    border:0 none;
    padding:0;
    > legend{
      display:none !important;
    }
    > fieldset,
    > .fieldset-wrapper{
      padding-left:0 !important;
      padding-right:0 !important;
    }
  }
  .horizontal-tab-hidden{
    display:none;
  }
}

/* Secondary */
ul.secondary{
  margin:0 0 8px 0;
  padding:0.55em 10px;
  border-width: 0 1px 1px 1px;
  border-style:solid;
  line-height:normal;
  border-color:$grey_light;
  background:white;
  @include clearfix-after;
  li{
    border-color:#eee;
    float:left;
    margin-right:7px;
    padding-right:7px;
    list-style:none;
    border-width:0 1px 0 0;
    border-style:solid;
    &:last-child{ border-width:0; }
    a{
      display:block; text-decoration:none;
    }
  }
}

/* Vertical tabs */
div.vertical-tabs{
  margin:1em 0px 1em 0;
  width:100%;
  border:0 none;
  @include box-sizing(border-box);
  ul.vertical-tabs-list{
    margin:0;
    padding:0;
    list-style:none;
    border-width:0 3px 0 0;
    border-style:solid;
    border-color:$highlight;
    @include box-sizing(border-box);
    @media #{$medium-up}{
      width:30%;
      float:left;
    }
    li{
      border:0 none;
      margin-bottom:2px;
      a{
        color:$text_color;
        text-decoration:none;
        display:block;
        padding:10px;
        line-height:1.1em;
        border-width:1px 0 1px 1px;
        border-style:solid;
        border-color:white;
        background-color:white;
        .form-required{
          color:white;
        }
        strong{
          font-weight:normal;
        }
        .summary{
          display:block;
          color:$grey;
          font-size:$font_small_px;
        }
        &:hover{
          outline:0;
        }
      }
      &:hover{
        border-color:$grey_light;
        background:white;
      }
      &.selected{
        a{
          background:$highlight;
          border-color:$highlight;
          border-radius:$border_radius 0 0 $border_radius;
          .summary{
            color:$highlight_text_color;
            opacity:0.8;
          }
        }
        strong{
          color:white;
        }
      }
    }
  }
  .vertical-tabs-panes{
    clear:both;
    border-width:0 0 3px 0;
    border-style:solid;
    border-color:$highlight;
    margin-left:-3px; // prevent double border (we use this workaround to get a 100% height border)
    @include box-sizing(border-box);
    @media #{$medium-up}{
      clear:none;
      float:left;
      width:70%;
      border-width:0 0 0 3px;
    }
  }
  .vertical-tabs-pane{
    margin:0;
    border:0 none;
    @include box-sizing(border-box);
    > legend{
      display:none;
    }
  }
}

/* Action Links */
ul.action-links {
  margin: 10px 0 $grid_vertical 0;
  padding:0;
  list-style: none;
  > li {
    > a{
      @include btn(ghost);
    }
  }
}

/* jQuery Tabs */
.ui-tabs{
  border:0 none;
}

#backtotop{
  background-color:transparent !important;
  border:0 none !important;
}
